<template>
  <div>
    <div class="multi-lang"> 
      <u class="linguval">{{$t("TERMS OF USE")}} </u>
    </div  >
    <div v-html="dataset"> </div>
 
  </div>
  
  
</template>

<script>
export default {
 data() {
  return {
    dataset :''
  }
 },
 mounted () {
  document.title= "Terms of Use - Saina Play";   
  var allMetaElements = document.getElementsByTagName('meta');
  for(var metaValue = 0; metaValue < allMetaElements.length; metaValue ++) {
  if (allMetaElements[metaValue].getAttribute("name") == "description") { 
       allMetaElements[metaValue].setAttribute('content', "Terms of Use - Saina Play"); 
  } 

  if (allMetaElements[metaValue].getAttribute("property") == "og:description") { 
    allMetaElements[metaValue].setAttribute('content', "Terms of Use - Saina Play"); 
  } 

  if(allMetaElements[metaValue].getAttribute("name") == "twitter:description") { 
     allMetaElements[metaValue].setAttribute('content', "Terms of Use - Saina Play"); 
  } 
  if(allMetaElements[metaValue].getAttribute("name") == "keyword") { 
     allMetaElements[metaValue].setAttribute('content', "Terms of Use - Saina Play"); 

  }
  if(allMetaElements[metaValue].getAttribute("property") == "og:title") { 
     allMetaElements[metaValue].setAttribute('content', "Terms of Use - Saina Play"); 
  }
  if(allMetaElements[metaValue].getAttribute("name") == "twitter:title") { 
     allMetaElements[metaValue].setAttribute('content', "Terms of Use - Saina Play"); 
  }

}

document.body.scrollTop = 0; // For Safari
  document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
  fetch('https://static-templates.web.app/saniaplay/en/termsofuse.html').then((res) => {
    return res.text()
  }).then((result) => {
    this.dataset = result;
  })
 }
}
</script>

<style>
.multi-lang{
  text-align: center;
  color: #777;
  /* background-color: black; */
  font-size: 24px !important;
  font-weight:bold;
  padding: 1rem;
}

/* .linguval{
   
    position: relative;
    top:20px
} */
@media (max-width: 576px) {
  .multi-lang{
    padding: 1rem;
  }
}

</style>